@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
.p-float-label > label {
  left: 22px;
}
@keyframes rotating {
  from {
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -ms-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

input[type="password"]::-ms-reveal {
  display: none !important;
}
.flap-input {
  &.has-prepend {
    position: relative;
    input {
      padding-left: 38px;
    }
  }
  &.ant-input-search {
    width: 280px;
  }
  input, select, textarea {
    border: 1px solid #DAEAF2;
    border-radius: 29px;
    font-size: 14px;
    padding: 9px 22px;
    &:focus, &:active, &:hover {
      border-color: #DAEAF2 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .prepend-icon {
    position: absolute;
    top: 34px;
    left: 18px;
  }
}

.success-btn {
  background: linear-gradient(221.93deg, #11e2ff 2.26%, #0046ff 130.63%);
    border-radius: 22px !important;
    color: #fff;
    padding: 8px 18px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: fit-content;
    &:hover {
      color: #fff;
    }
}

.success-btn-bordered {
    border-radius: 22px;
    color: #11E2FF;
    border: 1px solid #11E2FF;
    height: fit-content;
    padding: 8px 18px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: #11E2FF;
    }
}
.secondary-btn {
    border-radius: 22px;
    border: 1px solid #333;
    color: #000;
    padding: 8px 18px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border: 1px solid #333;
    }
}

.btn-primary {
  background: linear-gradient(180deg, #11e2ff 0%, #0572ff 78.12%, #0046ff 100%) !important;
    color: #fff !important;
    border-radius: 42px !important;
    font-size: 14px !important;
    border: none !important;
}

::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: transparent !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

div {
  text-align: left;
}

.custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

/* Add gradient to background when checked */
.custom-checkbox:checked {
  background-image: linear-gradient(180deg, #11e2ff 0%, #0572ff 78.12%, #0046ff 100%) !important;
  border: none;
}

/* Show the checkmark using a pseudo-element */
.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 4px;
    left: 7px;
    width: 5px;
    height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}