/* DownloadAppScreen.css */
.download-app {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // text-align: center;
    height: 100vh;
    background-color: #fff;
    padding: 20px;
  }
  
  .app-details {
    max-width: 600px;
    padding: 20px;
  }
  
  .download-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: white;
    background-color: #007bff;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  